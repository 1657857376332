// src/app.js
//import logo from './logo.svg';
import './App.css';
import {ethers} from 'ethers'
import { useEffect, useState, useRef, useCallback, Fragment, useMemo } from 'react';
import GuessTheseWordsArtifact from "./GuessTheseWords.json"
import { ErrorDecoder } from 'ethers-decode-error'
import { Buffer } from "buffer";
import Switch from "react-switch";
import React from "react";
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
//import Popup from "reactjs-popup";
//import ReactPaginate from 'react-paginate';
import Moralis from 'moralis';
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ReCAPTCHA from "react-google-recaptcha";
import {
  query,
  collection,
  onSnapshot,
  getFirestore,
  where,
  and,
  or,
} from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import Image from "./image.jpg";
import s1 from  "./1settings.jpg";
import s2 from  "./2network.jpg";
import s3 from  "./3addNetwork.jpg";
import s4 from  "./4addnetworkmanual.jpg";
import s5 from  "./5networkdetails.jpg";
import s6 from  "./6switchnetworks.jpg";
import s7 from  "./7copyaddress.jpg";
import s8 from  "./8bnbfaucet.jpg";
import s9 from  "./9websiteSwitchTestnet.jpg";
import s10 from "./10hereButton2.jpg";
import s11 from "./11MakeDeposit.jpg";
import s12 from "./12DepositConfirmation.jpg";
import s13 from "./13makeGuess.jpg";
import s14 from "./14makeGuessConfirmation.jpg";
import s15 from "./15generateWords.jpg";
import s16 from "./16generateWordsConfirmation.jpg";
import s17 from "./17waitGenerateWords.jpg";
import s18 from "./18scoring.jpg";
import s19 from "./19scoreConfirmation.jpg";
import s20 from "./20viewResults.jpg";
import s21 from "./addtestnet0.jpg";
import s22 from "./addtestnet1.jpg";
import s23 from "./addtestnet2.jpg";
//import { requestData, getTableDataFromJSONObject } from "./CommonFunctions.jsx";
//import { ReactTable } from "react-table";
//import { Table } from "react-bootstrap";
import { useTable, useSortBy, usePagination, useFilters, useExpanded } from "react-table";
import { Container, Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import TableContainer from './TableContainer';
import { SelectColumnFilter } from './filters';
import { generate } from "random-words";
//import { ReactTableDefaults } from "react-table";
//import MoralisCore from "@moralisweb3/core";
//import MoralisEvmApi from "@moralisweb3/evm-api";

//Object.assign(ReactTableDefaults, {
//  multiSort: false
//});

const coreParams = [
                     { // dont change the order of this list, only append new entries to the end of the list
		       "network": "bscmainnet",
                       "bscscan": "bscscan.com",
		       "opensea": "https://opensea.io/assets/bsc/",
		       "chainId": 56,
		       "gtwAddress": "0x4aE084e21C467990d70De0D0777Af21DA6e268c9", // IF updating here, also update in Moralis Streams setup, and NFT Authorized Source Addr
		       "NFTAddr": "0xBEf7064983512c0d4b6fb504273DDfe5557096DF",
		       "inputType": "password",
		       "maskOutput": true // whether or not to mask displayed word answers when they matches user guesses
		     },
                     {
		       "network": "bsctestnet",
                       "bscscan": "testnet.bscscan.com",
		       "opensea": "https://testnets.opensea.io/assets/bsc-testnet/",
		       "chainId": 97,
		       "gtwAddress": "0x86Ef0568F8919c64e7E6F13B90bFc8eFa4eE918a", // IF updating here, also update in Moralis Streams setup, and NFT Authorized Source Addr
		       "NFTAddr": "0xFE815123B5b2b4842159A1B02A724acafa1e98a8",
		       "inputType": "text",
		       "maskOutput": false // whether or not to mask displayed word answers when they matches user guesses
		     }
                   ]

// keep this for reference for managing mainnet VRF subscriptions
var gtwAddressOrig = "0x1f472682B683565C2ae6704fD900470aE8a19887"; // original address used to manage subscriptions

// Mainnet default
var networkIndex = 0;

//const Image = 'ipfs://QmVtrmaAWWR47utdweVkqCZq7xHNYRVQGH2L5UdNpcF1xZ'
//const Image = 'https://bafybeidqiid4o3olykw2jnnidqcmk436vwt5z25jhaka7dkrcu25ru5qsy.ipfs.dweb.link/'
const errorDecoder = ErrorDecoder.create()

await Moralis.start({
  apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjFjNDk1OGNkLWEyN2MtNGYzMC05NWY2LTFiNzlhOTAxMGIzNSIsIm9yZ0lkIjoiMzc1MjE4IiwidXNlcklkIjoiMzg1NTkxIiwidHlwZUlkIjoiNDUwMjNmODAtMTY0OS00ZGRjLTk5MDUtZjQwNmU2MzkzYzM1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDY3NjI1MTAsImV4cCI6NDg2MjUyMjUxMH0.YS0SioUaCBDl2LG3fWD8BxJyztuf6OH3ELEINJZI2Dk",
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhA1Fd8bxQYwg6CV0IlZwRUNJhE-OaNsU",
  authDomain: "ageofascention.firebaseapp.com",
  databaseURL: "https://ageofascention-default-rtdb.firebaseio.com",
  projectId: "ageofascention",
  storageBucket: "ageofascention.appspot.com",
  messagingSenderId: "317913064879",
  appId: "1:317913064879:web:5725dbc33b0411c56f3b5a",
  measurementId: "G-LSM23BQ42N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
//let database = firebase.database();

//
//Moralis.EvmApi.block.getBlock({
//  chain: 97,
//  blockNumberOrHash: "",
//});

//export interface GetBalanceData {
//  address: string;
//}
//
//export const getBalance = functions.https.onCall(
//  async (data: GetBalanceData) => {
//    const result = await Moralis.EvmApi.balance.getNativeBalance({
//      chain: EvmChain.ETHEREUM,
//      address: gtwAddress,
//    });
//    return {
//      balance: result.result.balance.ether,
//    };
//  }
//);


//const data = [
//  {
//    id: 1,
//    name: "John Doe",
//    age: 30,
//    occupation: "Software Engineer",
//  },
//  {
//    id: 2,
//    name: "Jane Doe",
//    age: 25,
//    occupation: "Web Developer",
//  },
//  {
//    id: 3,
//    name: "Peter Parker",
//    age: 20,
//    occupation: "Student",
//  },
//];
//
//const columns = [
//  {
//    Header: "Name",
//    accessor: "name",
//  },
//  {
//    Header: "Age",
//    accessor: "age",
//  },
//  {
//    Header: "Occupation",
//    accessor: "occupation",
//  },
//];

function App() {
  const [tokenData, setTokenData] = useState({});
  const [blarg, setBlarg] = useState("lightgreen");
  const [render ,setRender] = useState("");
  const changeColor = () => setBlarg("lightblue");
  const resetState = () => setSubmitState(-2);
  var [mostRecentSubmissionData, setMostRecentSubmissionData] = useState([]);
  var [mostHighscoreData, setHighscoreData] = useState([]);
  var [submitState, setSubmitState] = useState();
  var [stateDisplayText, setStateDisplayText] = useState();
  var [stateDisplayText2, setStateDisplayText2] = useState();
  var [topText, setTopText] = useState();
  var [stateGuessesAndAnswers, setStateGuessesAndAnswers] = useState([]);
  var [stateGuessesAndAnswers2, setStateGuessesAndAnswers2] = useState([]);
  const [word1, setWord1] = useState();
  const [word2, setWord2] = useState();
  const [word3, setWord3] = useState();
  const [word4, setWord4] = useState();
  const [word5, setWord5] = useState();
  const [word6, setWord6] = useState();
  const [word7, setWord7] = useState();
  const [word8, setWord8] = useState();
  const [word9, setWord9] = useState();
  const [word10, setWord10] = useState();
  const [word11, setWord11] = useState();
  const [word12, setWord12] = useState();
  const [userEmail, setUserEmail] = useState();
  const [nft, setNFT] = useState(true);
  const [showHighscores, setShowHighscores] = useState(false);
  const [showTenMostRecent, setShowTenMostRecent] = useState(false);
  const [depositDisabled, setDepositDisabled] = useState("");
  const [subDisabled, setSubDisabled] = useState("");
  const [genRndWrdsDisabled, setGenRndWrdsDisabled] = useState("");
  const [scoreBtnDisabled, setScoreBtnDisabled] = useState("");
  const [mostRecentSubmissionsDisabled, setMostRecentSubmissionsDisabled] = useState("");
  const [mostRecentSubButton, setMostRecentSubButton] = useState(1);
  const [pageOffset, setPageOffset] = useState([]);
  const [prevPageVisible, setPrevPageVisible] = useState(false);
  const [nextPageVisible, setNextPageVisible] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState("");
  const [nextDisabled, setNextDisabled] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [donationAmt, setDonationAmt] = useState("");
  const [donationDisabled, setDonationDisabled] = useState("");
  const [networkSwitchDisabled, setNetworkSwitchDisabled] = useState("");
  const [donationText, setDonationText] = useState("");
  const [displayEmail, setDisplayEmail] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [rngReset, setRngReset] = useState(false);
  const [txs, setTxs] = useState(null);

  const _isMounted = useRef(false);
  const userTableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(null);
  const [tableData, setData] = useState([]);
  const sort = [{"submitterAddr": "submitterAddr","desc": false}];  


  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const [selectedItem, setSelectedItem] = useState(6);
  const [selectedNetwork, setSelectedNetwork] = useState("bscmainnet");

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

//  const userData = [ // NDE = near death experience, CH = channeling, RV = remote viewing, "" = other/unknown
//	             {
//		       "name": "John Doe",
//		       "website": "JohnDoe.example.com",
//		       "email": "JohnDoe@example.com",
//		       "solutionType": "NDE",
//		       "tx": "0x53d2b6870a9b7d60f6641a6181cd0ae26782d107ea28aa81f08147a9b927ad5d"
//		     },
//	             {
//		       "name": "Jane Doe",
//		       "website": "",
//		       "email": "JaneDoe@example.com",
//		       "solutionType": "CH",
//	               "tx": "0x4496088468f21c4aa765fdc875a6f6eefacd608ae052ec68ee9938a9f5ce3d7b"
//	             },
//	             {
//		       "name": "John Smith",
//		       "website": "JohnSmith.example.com",
//		       "email": "",
//		       "solutionType": "",
//	               "tx": "0x92df47f26f59a7835554676d1f072f2a96e13c55929108ad401986ad79710fb2"
//		     },
//	             {
//		       "name": "Jane Smith",
//		       "website": "",
//		       "email": "",
//		       "solutionType": "RV",
//	               "tx": "0x01eb1e67c7d1841147df8483060c4f5b736506041f9ea0e267216dcee47d2af1"
//		     },
//	             {
//		       "name": "",
//		       "website": "",
//		       "email": "",
//		       "solutionType": "test",
//	               "tx": "0x96a0e92c53ad26f1fe68e0112269b397f5bc4e31457625520ae61f6625316391"
//		     },
//                   ];
     const userData = {
                        "0x5f8190436c033df6045ce38ea30f83a137e1a2c1":
	                  {
		            "name": "Jane Smith", // only required field
		            "website": "https://johndoe.example.com",
		            "email": "mailto:JohnDoe@example.com",
		          },
	                "0xa1b49aa48f00b0015209ae61d46a9129efa5c6a1":
	                  {
		            "name": "Jane Doe",
		            //"website": "",
		            "email": "mailto:JaneDoe@example.com",
		          },
	                "0xffdd79a9e45f9a49016e3f3fddd6435ac6c75223":
	                  {
		            "name": "John Smith",
		            "website": "https://johnsmith.example.com",
		            //"email": "",
		          },
	                "0x70499b639b084f789c034584a6392895fef4d600":
	                  {
		            "name": "John Doe",
		            //"website": "",
		            //"email": "",
		          },
	                "0x96a0e92c53ad26f1fe68e0112269b397f5bc4e31457625520ae61f6625316391":
	                  {
		            "name": "",
		            //"website": "",
		            //"email": "",
		          },
     };
     const userDataTX = {
                        "0x53d2b6870a9b7d60f6641a6181cd0ae26782d107ea28aa81f08147a9b927ad5d":
	                  {
		            "solutionType": "NDE",
		          },
	                "0x4496088468f21c4aa765fdc875a6f6eefacd608ae052ec68ee9938a9f5ce3d7b":
	                  {
		            "solutionType": "Channeling",
		          },
	                "0x92df47f26f59a7835554676d1f072f2a96e13c55929108ad401986ad79710fb2":
	                  {
		            //"solutionType": "",
		          },
	                "0x01eb1e67c7d1841147df8483060c4f5b736506041f9ea0e267216dcee47d2af1":
	                  {
		            "solutionType": "Remote View",
		          },
	                "0x96a0e92c53ad26f1fe68e0112269b397f5bc4e31457625520ae61f6625316391":
	                  {
		            "solutionType": "",
		          },
	                //"0x51dda7195a2c8450252f0193797cfce897679ea032ba915cdbd060bf582ac2c4":
	                //  {
		        //    "solutionType": "Testing",
			//  },
                      };
  const bodyText = "You passed the screening test on AgeOfAscension.com! \n\nTo make this official on the blockchain, please send this email with the following information: \n\n1. The 6-12 words you want to guess on the blockchain. \n<YOUR ANSWER HERE>\n\n2. Whether you would like an NFT, in the event you get a perfect score. \n<YOUR ANSWER HERE>\n\n3. Your name, if you wish to include this information alongside your guess on the AgeOfAscension.com website. \n<YOUR ANSWER HERE>\n\n4. Your website and/or email, in the event you wish to include this information alongside your guess on the AgeOfAscension.com website. \n<YOUR ANSWER HERE>\n\n5. Optionally, you may include the method by which you make your guesses - e.g., Channeling, Remote Viewing, NDE, or some other specified method.\n<YOUR ANSWER HERE>"
  const bodyTextFormatted = encodeURIComponent(bodyText);

  useEffect(() => {
    //const q = query(collection(db, "moralis/events/Gtwevents"), and(where("chainId", "==", coreParams[networkIndex].chainId), where("transactionHash", "!=","0x51dda7195a2c8450252f0193797cfce897679ea032ba915cdbd060bf582ac2c4")));
    const q = query(collection(db, "moralis/events/Gtwevents"), where("chainId", "==", coreParams[networkIndex].chainId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempTxs = [];
      querySnapshot.forEach((doc) => {
        tempTxs.push(doc.data());
      });
      setData(tempTxs); // add to main table
    });
  }, [selectedNetwork]);


const renderRowSubComponent = (row) => {
    const {
      name: { first, last },
      location: { city, street, postcode },
      picture,
      cell,
    } = row.original;
    return (
      <Card style={{ width: '18rem', margin: '0 auto' }}>
        <CardImg top src={picture.large} alt='Card image cap' />
        <CardBody>
          <CardTitle>
            <strong>{`${first} ${last}`} </strong>
          </CardTitle>
          <CardText>
            <strong>Phone</strong>: {cell} <br />
            <strong>Address:</strong>{' '}
            {`${street.name} ${street.number} - ${postcode} - ${city}`}
          </CardText>
        </CardBody>
      </Card>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Score',
        Filter: SelectColumnFilter,
        accessor: 'numExactMatches',
	Cell: ({ value, row }) => <span><a href={"https://"+coreParams[networkIndex].bscscan+"/tx/"+row.original.transactionHash} style={{ color: 'red' }}>{value}</a> / {row.original.numGuesses}</span>,
      },
      {
        Header: '# Rnd Srcs',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        accessor: 'numRNGProviders',
      },
      {
        Header: 'Contact',
        disableSortBy: true,
        accessor: 'notes1',
	Cell: ({ value, row }) => <span>{row.original.submitterAddr in userData ? "website" in userData[row.original.submitterAddr] ? <a href={userData[row.original.submitterAddr].website} style={{ color: 'red' }}>{userData[row.original.submitterAddr].name}</a> : "email" in userData[row.original.submitterAddr] ? <a href={userData[row.original.submitterAddr].email} style={{ color: 'red' }}>{userData[row.original.submitterAddr].name}</a> : <a href={"https://"+coreParams[networkIndex].bscscan+"/address/"+row.original.submitterAddr} style={{ color: 'red' }}>{userData[row.original.submitterAddr].name}</a> : <a href={"https://"+coreParams[networkIndex].bscscan+"/address/"+row.original.submitterAddr} style={{ color: 'red' }}>{row.original.submitterAddr.slice(0,8)}...</a> }</span>
      },
      {
        Header: 'NFT',
        disableSortBy: true,
        accessor: 'notes2',
	Cell: ({ value, row }) => <span>{row.original.tokenId > 0 ? <a href={coreParams[networkIndex].opensea + coreParams[networkIndex].NFTAddr + '/'+row.original.tokenId} style={{ color: 'red' }}>NFT{row.original.tokenId}</a> : "" }</span>
      },
      {
        Header: 'Notes',
        disableSortBy: true,
        accessor: 'notes3',
	Cell: ({ value, row }) => <span>{row.original.transactionHash in userDataTX ? "solutionType" in userDataTX[row.original.transactionHash] ? userDataTX[row.original.transactionHash].solutionType : "" : "" }</span>
      },
    ],
    []
  );


//  function fetchData(state) {
//
//    let pageSize = state === undefined ? 5 : state.pageSize;
//    let page = state === undefined ? 0 : state.page;
//    let sorted = state === undefined ? sort : state.sorted;
//
////    getUsers()
////      .then(res => {
////    txs?.map(res => {
//    //qwerty?.map(res => {
//	    //setTopText(res);
//        getTableDataFromJSONObject(qwerty, pageSize, page, sorted, "submitterAddr")
//          .then(result => {
//            if (_isMounted.current) {
//              setLoading(false);
//              setPages(result.pages === undefined ? 0 : result.pages);
//              setData(result.rows[0].submitterAddr);
//	      //console.log(result.rows[0].submitterAddr);
//	      //setDonationText(result.rows[0].submitterAddr.toString());
//	      //setDonationText(qwerty.toString());
//            }
//        })
//        .catch(error => {
//          console.warn(error);
//          if (_isMounted.current) {
//            setLoading(false);
//          }
//        });
//      //})
//      //.catch(error => {
//      //  console.warn(error);
//      //  if (_isMounted.current) {
//      //    setLoading(false);
//      //  }
//      //});
//  }

//  const handleNetworkChange = (event) => {
//    setSelectedNetwork(event.target.value);
//  };
async function HandleNetworkChange(network){
//const handleNetworkChange = (event) => {

  setNetworkSwitchDisabled("true");
  if (network == "bsctestnet"){
    networkIndex = 1;
  } else if (network == "bscmainnet"){
    networkIndex = 0;
  } else {
    // mainnet default
    networkIndex = 0;
  }
  //await useEffect();
  setSelectedNetwork(network);

  setNetworkSwitchDisabled("");
}

function changeNFTState(){
  if (nft){
	  setNFT(false);
  } else {
	setNFT(true);
  }
}

async function requestAccount() {
  await window.ethereum.request({ method: 'eth_requestAccounts' });
}

//const provider = new ethers.providers.Web3Provider(window.ethereum); //v5
//const signer = provider.getSigner(); //v5
 async function _initializeContract(init) {
  // We first initialize ethers by creating a provider using window.ethereum
  // When, we initialize the contract using that provider and the token's
  // artifact. You can do this same thing with your contracts.
  const contract = new ethers.Contract(
    coreParams[networkIndex].gtwAddress,
    GuessTheseWordsArtifact.abi,
    init
  );

  return contract;
}

async function makeDonation(){
  if (typeof window.ethereum !== 'undefined') {
    await setDonationDisabled("true");
    await setDonationText("");
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const signer = await provider.getSigner(); //v6
    const contract = await _initializeContract(signer); // "provider" needed to read from the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    //let amt = donationAmt.toString().trim();
    if (donationAmt == null || donationAmt.trim().length == 0){
        const displayText = <span style = {{ color: 'red' }}> Donation amount cannot be empty. </span>;
        await setDonationText(displayText);
        await setDonationDisabled("");
        await setDonationAmt("");
        return
    }

    try {
        //amt = Number(amt) / 1000000000000000000;
	// this line will fail if wallet balance is insufficient. make sure it displays this error correctly.
        //await contract.donate({value: amt.toString()});
        await contract.donate({value: ethers.parseUnits(donationAmt,18)});
    } catch (err) {
        const { reason } = await errorDecoder.decode(err);
        const displayText = <span style = {{ color: 'red' }}>Operation failed: {reason} </span>;
        await setDonationText(displayText);
        await setDonationDisabled("");
	await setDonationAmt("");
    }
    await setDonationAmt("");
  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setDonationText(errorText);
  }
}

async function makeDeposit(){
  if (typeof window.ethereum !== 'undefined') {
    await setDepositDisabled("true");
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const signer = await provider.getSigner(); //v6
    const contractWriter = await _initializeContract(signer); // "provider" needed to read from the environment
    const contractReader = await _initializeContract(provider); // "provider" needed to read from the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    try {
        //const requiredDeposit = Number(await contractReader.getRemainingDeposit()); // this fails - msg.sender doesnt work with reader?
        const requiredDeposit = Number(await contractReader.getRemainingDepositForAddr(account)); // this works
        //await setStateDisplayText(requiredDeposit); // testing
        //const requiredDeposit = await contract.getRequiredNativeTokenDeposit();
        //const requiredDeposit = Number(await contract.getRequiredNativeTokenDepositForAddr(account));
	// this line will fail if wallet balance is insufficient. make sure it displays this error correctly.
        await contractWriter.deposit({value: requiredDeposit.toString()}).then(txn => {guessFlow(-1,10);});
        //await contract.deposit({value: ethers.parseUnits(requiredDeposit.toString(),18)}).then(txn => {guessFlow(-1,10);});
    } catch (err) {
        const { reason } = await errorDecoder.decode(err);
        const displayText = <span style = {{ color: 'red' }}>Operation failed: {reason} </span>;
        await setStateDisplayText(displayText);
        await setDepositDisabled("");
    }
    //await new Promise(resolve => setTimeout(resolve, 2000));
    ////await setSubmitState(-1);
    //await guessFlow(-1);
  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}

async function getRandomNumbers(){
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const signer = await provider.getSigner(); //v6
    const contract = await _initializeContract(signer); // "provider" needed to read from the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    await setGenRndWrdsDisabled("true");
    try {
        await contract.getRandomNumbers().then(txn => {guessFlow(1,5000);});
    } catch (err) {
        const { reason } = await errorDecoder.decode(err);
        const displayText = <span style = {{ color: 'red', fontSize: 14 }}>{reason}</span>;
        await setStateDisplayText(displayText);
        await setGenRndWrdsDisabled("");
    }

  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}

async function scoring(){
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const signer = await provider.getSigner(); //v6
    const contract = await _initializeContract(signer); // "provider" needed to read from the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    setScoreBtnDisabled("true");

    try {
        const [mostRecentMatchingSubmissionIndex, foundSubmissionForAddrBool] = await contract.getMostRecentSubmissionByAddr(account);
        await contract.score().then(txn => {guessFlow(3,5000);});
    } catch (err) {
        const { reason } = await errorDecoder.decode(err);
        const displayText = <span style = {{ color: 'red', fontSize: 14 }}>{reason}</span>;
        await setStateDisplayText(displayText);
        setScoreBtnDisabled("");
    }

  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}

async function guessFlow(state,delay,newSubIdx,waitStart) {
  if (typeof window.ethereum !== 'undefined') {
    const waitText = <span style = {{ color: 'yellow' }}>Waiting... (may take a few moments)</span> ;
    if (waitStart == null || waitStart <= 0){
        setStateDisplayText(waitText);
    }
    await new Promise(resolve => setTimeout(resolve, delay));
    //const signer = await provider.getSigner(); //v6
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const contract = await _initializeContract(provider); // "provider" needed to read from the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    const objectNetwork = await provider.getNetwork();
    const userChainId = parseInt(objectNetwork.chainId.toString());
    if (userChainId == coreParams[0].chainId && selectedNetwork != "bscmainnet" ){
        const errorText = <span style = {{ color: 'red' }}>Error: Your wallet is configured for BSC Mainnet, but on this website you selected BSC TESTNET.</span> ;
        setStateDisplayText(errorText);
        return
    } else if (userChainId == coreParams[1].chainId && selectedNetwork != "bsctestnet"){
        const errorText = <span style = {{ color: 'red' }}>Error: Your wallet is configured for BSC TESTNET, but on this website you selected BSC Mainnet.</span> ;
        setStateDisplayText(errorText);
        return
    } else if (userChainId != coreParams[0].chainId && userChainId != coreParams[1].chainId){
        const errorText = <span style = {{ color: 'red' }}>Error: You must configure your wallet for either BSC Mainnet or BSC TESTNET.</span> ;
        setStateDisplayText(errorText);
        return
    }

    const submissionLength = await Number(await contract.getSubmissionDataLength());

    if (state == 0){
        setWord1("");
        setWord2("");
        setWord3("");
        setWord4("");
        setWord5("");
        setWord6("");
        setWord7("");
        setWord8("");
        setWord9("");
        setWord10("");
        setWord11("");
        setWord12("");
    }

    let foundSubmissionForAddrBool = false;
    let mostRecentMatchingSubmissionIndex = 0;
    //if (submissionLength > 0){
    try { // this will fail if there are no prior submissions from this account address.
        [mostRecentMatchingSubmissionIndex, foundSubmissionForAddrBool] = await contract.getMostRecentSubmissionByAddr(account);
    } catch {}
    //}
    //setStateDisplayText(newSubIdx + "," + mostRecentMatchingSubmissionIndex.toString()); // TESTING
    if (foundSubmissionForAddrBool && (state >= 0) && (waitStart == null || waitStart > 0)){
        const [submissionDate, submitterAddr, numGuesses, numExactMatches, numCorrectWords, perfectSubmission, tokenId, SubmissionState, mintNFT, numRNGProviders, notes] = await contract.submissionDataList(mostRecentMatchingSubmissionIndex);
	if (newSubIdx != null){
	      const d = new Date();
	      const seconds = d.getTime() / 1000;
	      if ( (Number(seconds) - Number(submissionDate)) > 60){ // If the last entry from this user is more than 60 seconds old, it probably isn't the current entry
        //    if (newSubIdx != mostRecentMatchingSubmissionIndex){ // guess hasn't been added yet to index
                await guessFlow(0,5000,newSubIdx);
                return
	    }
	}
	if (SubmissionState == 3){ // user must have already scored a previous submission
            await setSubmitState(3);
            // display scoring results for most recent submission
            //
            // Display scoring summary
            const displayText = "Your last submission got " + numExactMatches.toString() + " matches out of " + numGuesses.toString() + " words guessed!"
            await setTopText(displayText);
            // Display which words were wrong and the correct ones
            const [guesses, answers] = await contract.getGuessesAndAnswersStringsByIndex(mostRecentMatchingSubmissionIndex);
            //const [guessesytes, answersBytes] = await contract.getGuessesAndAnswersByIndex(mostRecentMatchingSubmissionIndex); // for testing
            var array3 = [];
	    for (let i=0; i<Math.min(guesses.length,answers.length); i++){
		var color = { color: 'red' };
		var result = 'WRONG'
	        let displayGuess = guesses[i];
	        let displayAnswer = answers[i];
		if (guesses[i] == answers[i]){
		    color = { color: 'green' };
		    result = 'CORRECT'
	            if (coreParams[networkIndex].maskOutput){
		        displayGuess = 'CORRECT';
			displayAnswer = 'CORRECT';
		    }
		}
                // display guesses and answers in a side-by-side in a table
                array3.push({"guess": displayGuess.trim().replace(/\0/g, ''), "answer": displayAnswer.trim().replace(/\0/g, ''), "color": color, "result":result});
	    }
            await setStateGuessesAndAnswers(array3);
            await setStateDisplayText("");

	    //const r = <button onClick={() => {setSubmitState(-2); guessFlow(-2,0)}} style = {{ fontSize: 20 }}> Start new submission </button> ;
	    //setRender(r);

            // give button to start a new submission, i.e. go to else statement code below

        } else if (SubmissionState == 2){ // rnd words issued, ready for scoring
	    if (state == 3){
                await guessFlow(3,5000);
		return
	    }
            await setSubmitState(2);
	    const displayText = "Random words have been received and your submission is ready for scoring:";
            await setTopText(displayText);
            await setScoreBtnDisabled("");
            await setStateDisplayText("");
		// disabled={scoreBtnDisabled}
	    //const r = <button onClick={scoring} style = {{ fontSize: 20 }} disabled={scoreBtnDisabled}> Score Submission </button> ;
	    //await setRender(r);
        } else if (SubmissionState == 1 || state == 1){ // rnd words requested, not yet issued
	    // If it has been more than 5 minutes, offer an option to go back and make another guess
            const d = new Date();
            const seconds = d.getTime() / 1000;
	    if (waitStart == null){
                await setSubmitState(0);
	        await setGenRndWrdsDisabled("true");
	        await guessFlow(1,5000,null,seconds);
	    } else {
                if ((Number(seconds) - Number(waitStart)) > 300){ // 300 = 5 minutes
		    if ((Number(seconds) - Number(waitStart)) < 310){
	                await setRngReset(true);
		    }
	        } else {
	            await setRngReset(false);
	        }
	        await guessFlow(1,5000,null,waitStart);
	    }

	    //const displayText = "Waiting for randon number generation...";
            //await setStateDisplayText(displayText);
        } else if (SubmissionState == 0){ // submission made, rnd words not yet requested
	    await setRngReset(false);
            await setSubmitState(0);
	    const displayText = "Generate random words for your submission:";
            await setTopText(displayText);
	    await setGenRndWrdsDisabled("");
            await setStateDisplayText("");
		// disabled={genRndWrdsDisabled}
	    //const r = <button onClick={getRandomNumbers} style = {{ fontSize: 20 }}> Generate Random Words </button> ;
	    //await setRender(r);
        }
    } else {
        //const requiredDeposit = Number(await contract.getRequiredNativeTokenDepositForAddr(account)) / 1000000000000000000;
        //const requiredDeposit = Number(await contract.getRemainingDeposit()) / 1000000000000000000; // this fails - msg.sender doesnt work with reader?
        const requiredDeposit = Number(await contract.getRemainingDepositForAddr(account)) / 1000000000000000000; // this works
	//setTopText(topText + requiredDeposit.toString()); // debugging
	if (state == 0){
	    await guessFlow(0,5000); // answer not yet added to index, wait for it
            return
	} else if (requiredDeposit > 0.0){
	    if (state == -1){
                await guessFlow(-1,5000,null,waitStart);
		return
            }
            await setSubmitState(-2);
            const displayText = "To make a submission, you need to first deposit " + requiredDeposit.toString() + " BNB."
	    await setTopText(displayText);
            await setDepositDisabled("");
            await setStateDisplayText("");
		// disabled={depositDisabled}
	    //const r = <button onClick={makeDeposit} style = {{ fontSize: 20 }}> Make Deposit </button> ;
	    //await setRender(r);
            // display deposit button
        } else {
            await setSubmitState(-1);
            // need boxes to input 6-12 guesses and dropdown menu to select # of guesses; email, nft, etc
            // this needs to be made into a button
	    await setStateDisplayText("");
	    await setTopText("");
            //await setRender("");
            await setSubDisabled("");
            await setStateDisplayText("");
	    //const r = `How many words would you like to guess?
	    //          How many words would you like to guess?`;
	    //await setRender(r);
            //var guesses = [];
            //var mintNFT = true;
            //const result = await contract.makeSubmission(guesses, mintNFT);
        }
    }




  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}
//guessFlow();

function NFTLink(NFTContractAddress, tokenId) {

    // Update the notes field with a link to the NFT
    //const NFT_URL = "https://opensea.io/assets/bsc/" + NFTContractAddress + "/" + tokenId
    //return '<a href="https://opensea.io/assets/bsc/' + NFTContractAddress + '/' + tokenId + '">NFT</a> '
    return coreParams[networkIndex].opensea + NFTContractAddress + '/' + tokenId
    // '<a href="' + NFT_URL + '"</a>'

}

async function highscores() {
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    ////const signer = await provider.getSigner(); //v6
    const contract = await _initializeContract(provider); // "provider" needed to read from the environment
    //const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    const length = Number(await contract.numHighScores());
      var array2 = [];
      for (let i=0; i<length; i++){
          const [submissionDate, submitterAddr, numGuesses, numExactMatches, numCorrectWords, perfectSubmission, tokenId, SubmissionState, mintNFT, numRNGProviders, notes] = await contract.submissionDataList(contract.highScores(i));
	  var NFT = "";
	  if (tokenId > 0){
	      const nftAddr = await contract.nftContractAddr();
	      NFT = await NFTLink(nftAddr, tokenId);
	  }
          array2.push({"submissionDate":submissionDate, "submitterAddr":submitterAddr, "numGuesses":numGuesses, "numExactMatches":numExactMatches, "numCorrectWords":numCorrectWords, "perfectSubmission":perfectSubmission, "tokenId":tokenId, "SubmissionState":SubmissionState, "mintNFT":mintNFT, "numRNGProviders":numRNGProviders, "notes":notes, "NFT":NFT});
      }
      setHighscoreData(array2);
      setShowHighscores(true);
  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}

async function mostRecentSubmissions(pageChange){
    setMostRecentSubmissionsDisabled("true");
    setPrevDisabled("true");
    setNextDisabled("true");
    const waitText = <span style = {{ color: 'yellow' }}>Waiting... (may take a few moments)</span> ;
    setStateDisplayText(waitText);
    const response = await Moralis.EvmApi.utils.runContractFunction({
      "chain": "0x61", // 0x61 = bsc testnet, 0x38 = bsc mainnet
      "functionName": "getSubmissionDataLength",
      "address": coreParams[networkIndex].gtwAddress,
      "abi": GuessTheseWordsArtifact.abi
    });
    const length = await response.jsonResponse;

    const numResultsPerPage = 5;
    let currentOffset = length-1;
    let newStateValues = pageOffset;
    let cp = currentPage;
    if (pageChange == "next"){
	cp = cp + 1;
	//currentOffset = pageOffset[pageOffset.length-1];
	currentOffset = pageOffset[cp];
    } else if (pageChange == "prev"){
	cp = cp - 1;
	//currentOffset = pageOffset[pageOffset.length-2];
	//newStateValues = pageOffset.slice(0,-1); // pop off last value in array
	//currentOffset = newStateValues[newStateValues.length - 1];
	currentOffset = pageOffset[cp];
	//setPageOffset(s);
    } else {
        newStateValues = [currentOffset]; // store the starting index value in the array
    }
    //await setStateDisplayText(newStateValues.length); // temp debugging
    var array = [];
    var count = 0;
    var nextPage = false;
    var prevPage = false;
    //if (newStateValues.length >= 2){
    if (cp > 0){
        prevPage = true;
    }
    //Math.max(0,pageOffset-numResultsPerPage)
    for (let i=currentOffset; i>=0; i--){
        if (count >= numResultsPerPage+1){ // need to look ahead by 1 entry to find if there's a next page to display or not
	    break
	}
        const response2 = await Moralis.EvmApi.utils.runContractFunction({
          "chain": "0x61", // 0x61 = bsc testnet, 0x38 = bsc mainnet
          "functionName": "submissionDataList",
	  "params": {"": i.toString()}, // need toString here, or else the zero index will throw and err.
          "address": coreParams[networkIndex].gtwAddress,
          "abi": GuessTheseWordsArtifact.abi
        });
	const  submissionDate = await response2.jsonResponse.submissionDate;
	const  submitterAddr = await response2.jsonResponse.submitterAddr;
	const  numGuesses = await response2.jsonResponse.numGuesses;
	const  numExactMatches  = await response2.jsonResponse.numExactMatches;
	const  numCorrectWords = await response2.jsonResponse.numCorrectWords;
	const  perfectSubmission = await response2.jsonResponse.perfectSubmission;
	const  tokenId = await response2.jsonResponse.tokenId;
	//const  SubmissionState  = await response2.jsonResponse.SubmissionState; // "Undefined" for SubmissionState, unsure of real name
	const  SubmissionState  = await response2.jsonResponse[7]; // going by order... will need to update if order changes
	const  mintNFT  = await response2.jsonResponse.mintNFT;
	const  numRNGProviders  = await response2.jsonResponse.numRNGProviders;
	const  notes = await response2.jsonResponse.notes;
	//await setTopText(i + "," + submissionDate + "," + SubmissionState); // for testing
	if (SubmissionState == 3) {
              var NFT = "";
              if (tokenId > 0){
                  const response = await Moralis.EvmApi.utils.runContractFunction({
                    "chain": "0x61", // 0x61 = bsc testnet, 0x38 = bsc mainnet
                    "functionName": "nftContractAddr",
                    "address": coreParams[networkIndex].gtwAddress,
                    "abi": GuessTheseWordsArtifact.abi
                  });
                  const nftAddr = await response.jsonResponse;
                  //const nftAddr = await contract.nftContractAddr();
                  NFT = await NFTLink(nftAddr, tokenId);
              }
	      if (count == numResultsPerPage) {
                  nextPage = true;
		  //newStateValues = newStateValues + [i];
		  if (cp < newStateValues.length){
                      newStateValues[cp+1] = i;
		  } else {
		      newStateValues = newStateValues + [i];
		  }
	      } else {
                  array.push({"submissionDate":submissionDate, "submitterAddr":submitterAddr, "numGuesses":numGuesses, "numExactMatches":numExactMatches, "numCorrectWords":numCorrectWords, "perfectSubmission":perfectSubmission, "tokenId":tokenId, "SubmissionState":SubmissionState, "mintNFT":mintNFT, "numRNGProviders":numRNGProviders, "notes":notes, "NFT":NFT});
	      }
              count += 1;
          }
    }
    //if (!nextPage && prevPage){
    //    newStateValues = newStateValues + [0];
    //}
    await setPageOffset(newStateValues);
    await setCurrentPage(cp);
    await setMostRecentSubmissionData(array);
    await setShowTenMostRecent(true);
    setPrevDisabled("");
    setNextDisabled("");
    setMostRecentSubmissionsDisabled("");
    setStateDisplayText("");
    setMostRecentSubButton(0);
    setPrevPageVisible(prevPage);
    setNextPageVisible(nextPage);
}

async function tenMostRecentSubmissions(){
  //chain: EvmChain.ETHEREUM,
  if (typeof window.ethereum !== 'undefined') {
    //const provider = new ethers.WebSocketProvider("wss://eth-mainnet.g.alchemy.com/v2/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjFjNDk1OGNkLWEyN2MtNGYzMC05NWY2LTFiNzlhOTAxMGIzNSIsIm9yZ0lkIjoiMzc1MjE4IiwidXNlcklkIjoiMzg1NTkxIiwidHlwZUlkIjoiNDUwMjNmODAtMTY0OS00ZGRjLTk5MDUtZjQwNmU2MzkzYzM1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MDY3NjI1MTAsImV4cCI6NDg2MjUyMjUxMH0.YS0SioUaCBDl2LG3fWD8BxJyztuf6OH3ELEINJZI2Dk");
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    ////const signer = await provider.getSigner(); //v6
    const contract = await _initializeContract(provider); // "provider" needed to read from the environment
    //const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    const length = Number(await contract.getSubmissionDataLength());
      var array = [];
      var count = 0;
      for (let i=length-1; i>=0; i--){
          if (count >= 5) {
              break
          }
          const [submissionDate, submitterAddr, numGuesses, numExactMatches, numCorrectWords, perfectSubmission, tokenId, SubmissionState, mintNFT, numRNGProviders, notes] = await contract.submissionDataList(i);
          if (SubmissionState == 3) {
              var NFT = "";
              if (tokenId > 0){
        	  const nftAddr = await contract.nftContractAddr();
                  NFT = await NFTLink(nftAddr, tokenId);
              }
              array.push({"submissionDate":submissionDate, "submitterAddr":submitterAddr, "numGuesses":numGuesses, "numExactMatches":numExactMatches, "numCorrectWords":numCorrectWords, "perfectSubmission":perfectSubmission, "tokenId":tokenId, "SubmissionState":SubmissionState, "mintNFT":mintNFT, "numRNGProviders":numRNGProviders, "notes":notes, "NFT":NFT});
              count += 1;
          }
      }
      setMostRecentSubmissionData(array);
      setShowTenMostRecent(true);
  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}
//tenMostRecentSubmissions();

async function makeSubmission2() {
    let words = [];
    await setStateDisplayText2("");
    await setSubDisabled("true");

    if (selectedItem == 6 || selectedItem == null){
        words = [word1, word2, word3, word4, word5, word6];
    } else if (selectedItem == 8){
        words = [word1, word2, word3, word4, word5, word6, word7, word8];
    } else if (selectedItem == 10){
        words = [word1, word2, word3, word4, word5, word6, word7, word8, word9, word10];
    } else if (selectedItem == 12){
        words = [word1, word2, word3, word4, word5, word6, word7, word8, word9, word10, word11, word12];
    }

    for (let w=0; w<words.length; w++){
        if (words[w] != null){
            words[w] = words[w].trim();
	}
        if (words[w] == null || words[w] == ""){
            const displayText = <span style = {{ color: 'red' }}><br/>You cannot submit empty guesses.</span>;
            await setStateDisplayText2(displayText);
            await setSubDisabled("");
            return
        }
        words[w] = words[w].toLowerCase();
    }

    setWord1("");
    setWord2("");
    setWord3("");
    setWord4("");
    setWord5("");
    setWord6("");
    setWord7("");
    setWord8("");
    setWord9("");
    setWord10("");
    setWord11("");
    setWord12("");

    await new Promise(resolve => setTimeout(resolve, 3000));
    let array4 = [];
    let correctCount = 0;
    for (let w=0; w<words.length; w++){
        let color2 = { color: 'red' };
        let result2 = 'WRONG'
        let displayGuess2 = words[w];
        let displayAnswer2 = await generate();
        if (displayGuess2 == displayAnswer2){
            color2 = { color: 'green' };
            result2 = 'CORRECT'
            correctCount += 1;
        }
        // display guesses and answers in a side-by-side in a table
        array4.push({"guess": displayGuess2, "answer": displayAnswer2, "color": color2, "result":result2});
    }
    await setStateGuessesAndAnswers2(array4);
    if (correctCount >= 3){
        // user passed screening test
        setStateDisplayText2("");
        await setSubmitState(-7);
    } else {
        const displayText = <span style = {{ color: 'red' }}><br/>Sorry, you did not pass the screening test! Feel free to try again!</span>;
        setStateDisplayText2(displayText);
        await setSubmitState(-6);
    }
    await setSubDisabled("");
}

async function makeSubmission() {
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const signer = await provider.getSigner(); //v6
    const contractWriter = await _initializeContract(signer); // "singer" needed to write to the environment
    const contractReader = await _initializeContract(provider); // "singer" needed to write to the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //await setTopText("How many words would you like to guess?");
    let words = [];
    await setSubDisabled("true");

    if (selectedItem == 6 || selectedItem == null){
	words = [word1, word2, word3, word4, word5, word6];
    } else if (selectedItem == 8){
	words = [word1, word2, word3, word4, word5, word6, word7, word8];
    } else if (selectedItem == 10){
	words = [word1, word2, word3, word4, word5, word6, word7, word8, word9, word10];
    } else if (selectedItem == 12){
	words = [word1, word2, word3, word4, word5, word6, word7, word8, word9, word10, word11, word12];
    }

    for (let w=0; w<words.length; w++){
	if (words[w] != null){
	    words[w] = words[w].trim();
	}
        if (words[w] == null || words[w] == ""){
            const displayText = <span style = {{ color: 'red' }}>You cannot submit empty guesses.</span>;
            await setStateDisplayText(displayText);
            await setSubDisabled("");
            return
	}
	words[w] = words[w].toLowerCase();
    }

    // TODO: send notification if subscription balance < 15 LINK
    //const LINKBal = Number(await contractReader.getSubBalance("0"));
    //if (LINKBal < 15000000000000000000){ // 15 LINK
    //}

    try {
	// record to firebase database to get email?
	// Only admins are supposed to be able to write to database, not clients. Only works when client is authenticated and has permission
	//if (userEmail != null){
	//    if (userEmail.trim().length > 0){
	//	// This is the correct syntax, the issue is that the account lacks permissions.
	//        setDoc(doc(db,"emails","email"),{"account": account, "userEmail": userEmail});
	//    }
	//}

        await contractWriter.makeSubmission(words,nft).then(txn => {guessFlow(0,5000,1234);})
        //const subIdx = await Number(await contractWriter.makeSubmission(words,nft));
	//await guessFlow(0,5000,subIdx);
	return
    } catch (err) {
          const { reason } = await errorDecoder.decode(err);
          const displayText = <span style = {{ color: 'red', fontSize: 14 }}>{reason}</span>;
          await setStateDisplayText(displayText);
          await setSubDisabled("");
    }
  } else {
    const errorText = <span style = {{ color: 'red' }}>Error: Crypto Wallet extension not detected. Install a wallet extension (e.g. Metamask) to proceed.</span> ;
    setStateDisplayText(errorText);
  }
}

async function testasdf(){
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum); //v6
    const signer = await provider.getSigner(); //v6
    const contract = await _initializeContract(signer); // "singer" needed to write to the environment
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const subIdx = await contract.makeSubmission(["asdf","qwer","zxcv","poiu","hkjg","sdfj"],true); // assignment doesn't work properly using "signer"
    const qqqq = await subIdx.wait();
    //await setStateDisplayText(qqqq.toString());
    await setStateDisplayText(subIdx.toString());
  }
}

//  useEffect(() => {
//    //console.log("start");
//    const q = query(collection(db, "moralis/events/Gtwevents"));
//    //console.log("query has been setup");
//    const unsubscribe = onSnapshot(q, (querySnapshot) => {
//      const tempTxs = [];
//      querySnapshot.forEach((doc) => {
//        tempTxs.push(doc.data());
//      });
//      setTxs(tempTxs);
//    });
//
//
//    //Stop realtime updates:
//    //unsubscribe();
//  }, []);

// String.fromCharCode(8592) // left facing arrow
// String.fromCharCode(8593) // up facing arrow
// String.fromCharCode(8594) // right facing arrow

  return (
    <div className="App" style={{ backgroundImage: 'url(' + Image + ')', backgroundSize: 'cover', resize: 'both', backgroundAttachment: 'fixed'}}>
      <header className="App-header" style={{ opacity: '0.8', width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
<br/>


    {submitState == null ? <Container style={{ marginTop: 100 }}>
      <TableContainer
        columns={columns}
        data={tableData}
        renderRowSubComponent={renderRowSubComponent}
      />
    </Container> : "" }

  {submitState == null && (<select value={selectedNetwork} onChange={e => HandleNetworkChange(e.target.value)} style = {{ fontSize: 20 }} disabled={networkSwitchDisabled}>
        <option value="bscmainnet">BSC Mainnet</option>
        <option value="bsctestnet">BSC TESTNET</option>
      </select>)}



  {showHighscores === true && (<h1>Highscores (3+ correct words)</h1>)}
  {showHighscores === true && (<table>
    <tr>
      <th>Address</th>
      <th># Matches</th>
      <th># Words Guesses</th>
      <th># Rnd Sources</th>
      <th>Notes</th>
    </tr>
    {mostHighscoreData.map(d => (
      <tr>
        <td><a href={"https://"+coreParams[networkIndex].bscscan+"/address/"+d.submitterAddr} style={{ color: 'red' }}>{d.submitterAddr.slice(0,10)}...</a></td>
        <td>{d.numExactMatches.toString()}</td>
        <td>{d.numGuesses.toString()}</td>
	<td>{d.numRNGProviders.toString()}</td>
        <td>{d.NFT.length > 0 ? <a href={d.NFT} style={{ color: 'red' }}>NFT</a> : "" } {d.notes}</td>
      </tr>
      ))}
  </table>)}

  {showTenMostRecent === true && (<h1>Most recent submissions</h1>)}
  {showTenMostRecent === true && (<table>
    <tr>
      <th>Address</th>
      <th># Matches</th>
      <th># Words Guessed</th>
      <th># Rnd Sources</th>
      <th>Notes</th>
    </tr>
    {mostRecentSubmissionData.map(d => (
      <tr>
        <td><a href={"https://"+coreParams[networkIndex].bscscan+"/address/"+d.submitterAddr} style={{ color: 'red' }}>{d.submitterAddr.slice(0,10)}...</a></td>
        <td>{d.numExactMatches.toString()}</td>
        <td>{d.numGuesses.toString()}</td>
	<td>{d.numRNGProviders.toString()}</td>
        <td>{d.NFT.length > 0 ? <a href={d.NFT} style={{ color: 'red' }}>NFT</a> : "" } {d.notes}</td>
      </tr>
      ))}
  </table>)}

  {showTenMostRecent == true && (<view style={{ flexDirection:"row" }}>
      <view>
	  {prevPageVisible ? <button onClick={() => {mostRecentSubmissions("prev")}} disabled={prevDisabled}>{String.fromCharCode(8592)}</button> : ""}
      </view>
      <view>
	  {nextPageVisible ? <button onClick={() => {mostRecentSubmissions("next")}} disabled={nextDisabled}>{String.fromCharCode(8594)}</button> : ""}
      </view>
  </view>)}

  {submitState == 9 ? <button onClick={highscores} style = {{ fontSize: 20}}>Show Highscores</button> : "" }
  {submitState == 9 ? <button onClick={tenMostRecentSubmissions} style = {{ fontSize: 20}}>Show recent submissions</button> : "" }
  {submitState == 9 ? <button onClick={mostRecentSubmissions} style = {{ fontSize: 20}} disabled={mostRecentSubmissionsDisabled}>Moralis Results Render</button> : "" }

   {topText}

   {submitState === 3 && (<table>
    <tr>
      <th>Guess</th>
      <th>Answer</th>
      <th>Result</th>
    </tr>
    {stateGuessesAndAnswers.map(d => (
      <tr>
        <td style={d.color}>{d.guess.trim()}</td>
        <td style={d.color}>{d.answer.trim()}</td>
        <td style={d.color}>{d.result.trim()}</td>
      </tr>
      ))}
  </table>)}

      {submitState == null ? <h2>What is this?</h2> : "" }
      {submitState == null ? <span style={{ textAlign: "left", width: "75%" }}>This tool offers a way for you to prove that you have knowledge of the future, by guessing some randomly generated words!<br/><br/>
		      Whether you`re a channeler, remote viewer, someone with a paranormal experience, or you`re just feeling lucky - this tools provides a way to legitimize your experience and/or abilities to others, using the power of Web3 and Blockchain technology!</span> : "" }
      {submitState == -4 ? <button onClick={() => {setSubmitState(null);window.scrollTo(0, 0)}} style = {{ fontSize: 20 }}> Back to main page </button> : "" }
      {submitState == -4 ? <h2>What is this?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>This tool offers a way for you to prove that you have knowledge of the future, by guessing some randomly chosen words before they are generated!<br/><br/>
      This works by leveraging the blockchain to store and score your submissions. The upshot is that this computer code runs on a public network available for <a href={"https://"+coreParams[networkIndex].bscscan+"/address/"+coreParams[networkIndex].gtwAddress+"#code"} style={{ color: 'red' }}>anyone to audit</a> and verify that this is indeed a fair, unrigged* game.<br/><br/>
		      It also eliminates the problem of having to trust a single arbiter to adjudicate such a contest, creating a trustless verification system. The distributed nature of the blockchain network also makes it highly resistant to manipulation.</span> : "" }
      {submitState == null ? <h2>I am ready to make a guess!</h2> : "" }
      {submitState == null ? <span style={{ textAlign: "left", width: "75%" }}>Just click <a href="#" onClick={() => {setSubmitState(-5);window.scrollTo(0, 0)}} style={{ color: 'red' }}>here</a> to get started!</span> : "" }
      {submitState == null ? <h2>How do I read the table?</h2> : "" }
      {submitState == null ? <span style={{ textAlign: "left", width: "75%" }}>The table is sorted by Score, which shows the number of correct words out of the number of words guessed. Any score of 3 or above is noteworthy, as this is already less likely than winning the Mega Millions Jackpot!</span> : "" }
      {submitState == null ? <h2>FAQ</h2> : "" }
      {submitState == null ? <span style={{ textAlign: "left", width: "75%" }}>For more information, click <a href="#" onClick={() => {setSubmitState(-4);window.scrollTo(0, 0)}} style={{ color: 'red' }}>here</a>.</span> : "" }
      {submitState == -4 ? <h2>How does it work?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>On the main page, you are asked to guess 6-12 random words as an initial <b>screening test</b>. If you get 3 or more right, I will cover the transaction costs to "make it official" by submitting your chosen answers to the blockchain for validation.</span> : "" }
      {submitState == -4 ? <h2>Can I skip the screening test?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>The screening test is meant to make your life easier by not having to setup a crypto wallet. However, you may bypass the screening test and submit answers to the blockchain using your own crypto wallet if you wish.<br/><br/>
      Note that the process for setting up a crypto wallet for the first time is somewhat involved and is described in greater detail <a href="#" onClick={() => {setSubmitState(-3);window.scrollTo(0, 0)}} style={{ color: 'red' }}>here</a>.<br/><br/>
      Then, you may start the guessing process on the blockchain 
      <button onClick={() => {guessFlow(4,0)}} style = {{ fontSize: 20, marginLeft: 10, marginRight: 10 }}>here.</button><select value={selectedNetwork} onChange={e => HandleNetworkChange(e.target.value)} style = {{ fontSize: 20 }} disabled={networkSwitchDisabled}>
        <option value="bscmainnet">BSC Mainnet</option>
        <option value="bsctestnet">BSC TESTNET</option>
      </select><br/><br/></span> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>You will be walked through the steps, but an overview is:<br/><br/>
      <b>1.</b> Make the required deposit to fund most of the associated network fees.<br/>
      <b>2a.</b> Enter your guesses - 6, 8, 10, or 12 words (your choice). All lower case English words.<br/>
      <b>2b.</b> Repetition is allowed, i.e. the same answer can appear for multiple word guesses.<br/>
      <b>3.</b> Request random number generation and corresponding mapping to english words. This takes a good minute, depending on network congestion.<br/>
      <b>4.</b> Score your submission and view results.<br/><br/>
      There are no limits to the number of times you can do this.</span> : "" }
      {submitState == -4 ? <h2>What if I want to bypass this website?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>The initiated among you who desire to may interact with the blockchain contract directly, without the need to use this website. The instructions explaining which blockchain functions to call are described at the top of the contract code <a href={"https://"+coreParams[networkIndex].bscscan+"/address/"+coreParams[networkIndex].gtwAddress+"#code"} style={{ color: 'red' }}>here</a>.</span> : "" }

      {submitState == -3 ? <br/> : "" }
      {submitState == -3 ? <button onClick={() => {setSubmitState(-4);window.scrollTo(0, 0)}} style = {{ fontSize: 20 }}> Back to FAQ </button> : "" }
      {submitState == -3 ? 
	  <span style={{ textAlign: "left", width: "75%" }}>

		     <center><h1>Wallet Selection</h1></center>
		      First, you need to connect a crypto wallet. If you do not have one, <a href="https://metamask.io/download/" style={{ color: 'red' }}>Metamask</a> is a popular choice and the one that will be shown in the rest of this guide. Install and setup the browser extension on your desktop.<br/>

		     <center><h1>Network Addition/Selection</h1></center>
                     This app/website permits you to select between two networks: "BSC Mainnet" which requires about ~$3 worth of tokens per guess attempt, and "BSC Testnet" which is a free test network. Use Testnet for playing around and trying out the app. Only use Mainnet if you think you have got an answer for real! You can use the selector at the top of the main page to switch between networks:<br/>
                     <center><img  src={s9}/></center><br/>

                      Adjusting this option will change the displayed table data accordingly. On the testnet, you will see the fake winners I created for testing purposes. Adjusting this option will also change which network you guesses are submitted to, and whether or not you have to pay "real" money.<br/><br/>

                      In addition, you will need to configure your wallet to use the same matching network as you choose in the web app. To add the BSC Mainnet, start at <a href="https://bscscan.com/" style={{ color: 'red' }}/>. To add the BSC Testnet, start at <a href="https://testnet.bscscan.com/" style={{ color: 'red' }}/>. The rest of the process is (mostly) the same for both networks; screenshots will show the testnet being added.<br/><br/>

      Scroll to the very bottom of the page. Look to the left bottom corner and press the "Add" button to add the corresponding network. Ex for testnet:<br/><br/>
      <center><img src={s21}/></center><br/>

      Then approve the network addition:<br/><br/>
      <center><img src={s22}/></center><br/>

      Now switch to the newly added network:<br/><br/>
      <center><img src={s23}/></center>

      <center><h1>Getting Testnet tokens</h1></center>
      You are permitted a free daily allocation of tokens on testnet. To claim your free daily allocation, first copy your wallet address to your clipboard:<br/><br/>
      <center><img  src={s7}/></center><br/>

      Then, paste your wallet address at <a href="https://www.bnbchain.org/en/testnet-faucet"/> and press "Send 0.3 BNB" to fund your wallet with a free allocation of test network tokens. This is the "currency" used to pay miners on the network to perform and validate your transactions.<br/><br/>
      <center><img  src={s8}/></center>

      <center><h1>Getting Mainnet tokens</h1></center>
      If you make to make your guess "official" on the mainnet, you will need to purchase the BNB cryptocurrency to fund the costs for miners to perform and validate your transactions. How easy this will be will depend on the zipcode of your credit card or other payment option, as certain jurisdictions make this easier than others.<br/><br/>

      Plan to acquire ~0.015 BNB tokens for each guess you plan to make, but note that this amount can fluctuate depending on network congestion. Certain times of the week (like Sunday) are typically better than others. <a href="https://dappgrid.com/how-to-buy-bnb-on-metamask/" style={{ color: 'red' }}>This guide</a> shows you how to purchase BNB tokens in Metamask.<br/><br/>

      Note that you are at the mercy of volume requirements of liquidity providers. In other words, there is a minimum amount of the token that a third party is willing to sell you. When I last tried, $16 was the least I was able to exchange for BNB tokens.<br/>
		      
      <center><h1>Making a submission</h1></center>
      After following the above sections and configuring your wallet for the desired network, you are ready to make a submission on this website/app.<br/><br/>

      Scroll down and click the following button to start the submission process. Set the network here to match the desired network you have setup/configured in your wallet. Ex, using TESTNET:<br/><br/>
      <center><img  src={s10}/></center><br/>

      Now make the required deposit to fund the transaction costs related to acquiring random numbers/words:<br/><br/>
      <center><img src={s11}/></center><br/>

      For each blockchain transaction, you will get a popup from your wallet plugin that looks like this. Click "Confirm" to confirm the transaction. If you don`t get a popup from your wallet extension, try reloading the page and checking your wallets network configuration.<br/><br/>
      <center><img src={s12}/></center><br/>

      Select how many words you wish to guess and input your guesses. Of course a guess with numbers like "guess1" will not be a winning guess; this is just a demonstration of the process:<br/><br/>
      <center><img src={s13}/></center><br/>

      Confirm again the blockchain transaction:<br/><br/>
      <center><img src={s14}/></center><br/>

      Now, instruct the blockchain smart contract to generate your random words:<br/><br/>
      <center><img src={s15}/></center><br/>

      Confirm the blockchain transaction:<br/><br/>
      <center><img src={s16}/></center><br/>

      This step in particular will take the longest, as it requires sourcing entropy from multiple providers. These sources are not available 100% of the time, in which case the app will give you the option to start over if all random words have not been received within 5 minutes.<br/><br/>
      However, any subsequent attempts will run into the same problem until those random sources are back online. This is a particular issue with the random sources on the testnet.<br/><br/>
      <center><img src={s17}/></center><br/>

      After random words are generated, you may score your submission:<br/><br/>
      <center><img src={s18}/></center><br/>

      Confirm the transaction:<br/><br/>
      <center><img src={s19}/></center><br/>

      At last, view your results!<br/><br/>
      <center><img src={s20}/></center><br/>

      There is no limit to the number of times you can do this.<br/><br/>

		      </span> : "" }
      {submitState == -3 ? <button onClick={() => {setSubmitState(-4);window.scrollTo(0, 0)}} style = {{ fontSize: 20 }}> Back to FAQ </button> : "" }


      {submitState == -2 ? <button onClick={makeDeposit} style = {{ fontSize: 20 }} disabled={depositDisabled}> Make Deposit </button> : "" }
      {submitState == 0 ? <button onClick={getRandomNumbers} style = {{ fontSize: 20 }} disabled={genRndWrdsDisabled}> Generate Random Words </button> : "" }
      {submitState == 2 ? <button onClick={scoring} style = {{ fontSize: 20 }} disabled={scoreBtnDisabled}> Score Submission </button> : "" }
      {submitState == 3 ? <br/> : "" }
      {submitState == 3 ? <button onClick={() => {setSubmitState(-2); guessFlow(-2,0)}} style = {{ fontSize: 20, marginBottom: 20 }}> Start new submission </button> : "" }
      {submitState == 3 ? <button onClick={() => {setSubmitState(null); setTopText("")}} style = {{ fontSize: 20 }}> Back to main page </button> : "" }

      {submitState === -1 && ("How many words would you like to guess?")}
      {submitState === -1 && (<select value={selectedItem} onChange={handleChange} style = {{ fontSize: 20 }} disabled={subDisabled}>
        <option value="6">6 Words</option>
        <option value="8">8 Words</option>
        <option value="10">10 Words</option>
        <option value="12">12 Words</option>
      </select>)}
      {submitState === -1 && ("Your guesses - abdomen, camel, dizzy, etc:")}
      {submitState === -1 && (<input type={coreParams[networkIndex].inputType} onChange={e => setWord1(e.target.value)} value={word1} placeholder="1st word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && (<input type={coreParams[networkIndex].inputType} onChange={e => setWord2(e.target.value)} value={word2} placeholder="2nd word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && (<input type={coreParams[networkIndex].inputType} onChange={e => setWord3(e.target.value)} value={word3} placeholder="3rd word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && (<input type={coreParams[networkIndex].inputType} onChange={e => setWord4(e.target.value)} value={word4} placeholder="4th word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && (<input type={coreParams[networkIndex].inputType} onChange={e => setWord5(e.target.value)} value={word5} placeholder="5th word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && (<input type={coreParams[networkIndex].inputType} onChange={e => setWord6(e.target.value)} value={word6} placeholder="6th word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && selectedItem > 6 ? <input type={coreParams[networkIndex].inputType} onChange={e => setWord7(e.target.value)} value={word7} placeholder="7th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -1 && selectedItem > 6 ? <input type={coreParams[networkIndex].inputType} onChange={e => setWord8(e.target.value)} value={word8} placeholder="8th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -1 && selectedItem > 8 ? <input type={coreParams[networkIndex].inputType} onChange={e => setWord9(e.target.value)} value={word9} placeholder="9th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -1 && selectedItem > 8 ? <input type={coreParams[networkIndex].inputType} onChange={e => setWord10(e.target.value)} value={word10} placeholder="10th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -1 && selectedItem > 10 ? <input type={coreParams[networkIndex].inputType} onChange={e => setWord11(e.target.value)} value={word11} placeholder="11th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -1 && selectedItem > 10 ? <input type={coreParams[networkIndex].inputType} onChange={e => setWord12(e.target.value)} value={word12} placeholder="12th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      <label>
      {submitState === -1 && (<span>Mint NFT if you win: </span>)}
      {submitState === -1 && (<Switch onChange={changeNFTState} checked={nft} disabled={subDisabled} />)}
      </label>
      {submitState === -9 && (<input onChange={e => setUserEmail(e.target.value)} placeholder="Your Email (optional)" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -1 && (<button onClick={makeSubmission} style = {{ fontSize: 20 }} disabled={subDisabled}>Make Submission</button>)}
      {stateDisplayText}


      {submitState === 0 && rngReset && (<span style = {{ color: 'yellow' }}>No random numbers received. You may continue to wait, refresh the page, or make a new submission.</span>)}
      {submitState === 0 && rngReset && (<button onClick={() => {setRngReset(false); setSubmitState(-2); guessFlow(-2,0,null,-1)}} style = {{ fontSize: 20, marginBottom: 20 }}> Start new submission </button>)}








      {submitState == -4 ? <h2>What do I win if I guess right?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>Bragging rights, and optionally an NFT will be minted to your address if you get a perfect score - i.e. <a href="https://testnets.opensea.io/assets/bsc-testnet/0xFE815123B5b2b4842159A1B02A724acafa1e98a8/4" style={{ color: 'red' }}>6 out of 6 guesses</a>, <a href="https://testnets.opensea.io/assets/bsc-testnet/0xFE815123B5b2b4842159A1B02A724acafa1e98a8/6" style={{ color: 'red' }}>8/8</a>, <a href="https://testnets.opensea.io/assets/bsc-testnet/0xFE815123B5b2b4842159A1B02A724acafa1e98a8/7" style={{ color: 'red' }}>10/10</a>, or <a href="https://testnets.opensea.io/assets/bsc-testnet/0xFE815123B5b2b4842159A1B02A724acafa1e98a8/8" style={{ color: 'red' }}>12/12</a>. Promotion as a certified clairvoyant (see "I won!" section at the bottom of page).</span> : "" }
      {submitState == -4 ? <h2>Why guess words instead of numbers?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>Because guessing words is objectively more entertaining! Perhaps the clairvoyant among you will find it easier to guess than random numbers.</span> : "" }
      {submitState == -4 ? <h2>Why are guesses cut off when I view the results?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>This means you guessed a word that was too long and was truncated to fit in the allocated bytespace on the blockchain.</span> : "" }
      {submitState == -4 ? <h2>Can I have the list of english words I am guessing?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>If you were truly clairvoyant, you would not need to ask this question!</span> : "" }
      {submitState == -4 ? <h2>*Tell me more about randomness</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>The blockchain is deterministic by design, so random numbers are something we have to get from external sources.<br/><br/>
      This is not ideal because these external sources are opaque and could become compromised. It would not be the <a href="https://en.wikipedia.org/wiki/Hot_Lotto_fraud_scandal" style={{ color: 'red' }}>first time</a> someone rigged a random number generator to produce a desired result. There are many other examples where lottery officials turned out to be less than trustworthy (big shocker, I know).<br/><br/>
      While we doubt that someone would go through the same trouble for what amounts to bragging rights, we have taken the precaution sourcing random numbers from multiple VRF oracles (Chalink VRF and BSC native VRF), so that the compromise of any single one of these would not give up the game. More may be added in the future.<br/><br/>
      Note that the second column in the display table, `# Rnd Srcs` indicates the number of random sources used to generate the solution. For example, someone guessing 12 words with 2 random sources will have 6 words generated by one random source, and 6 generated by the other.</span> : "" }
      {submitState == -4 ? <h2>What are the odds of winning?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>
      There are 4936 possible words. There is no programmed limit to the number of times a single word can be drawn from the sample. A correct guess is only awarded for guessed words that matches its generated answer - e.g., word guess #4 only matches answer #4.<br/><br/>
      If you confine your guesses to valid word answers, then the probability matrix for getting different numbers of correct word guesses is as follows:</span> : "" }<br/>
{submitState == -4 ? <table>
    <tr>
      <th># Correct Guesses</th>
      <th>out of 6</th>
      <th>out of 8</th>
      <th>out of 10</th>
      <th>out of 12</th>
    </tr>
      <tr>
        <td>1</td>
        <td>0.0012</td>
        <td>0.0016</td>
        <td>0.0020</td>
        <td>0.0024</td>
      </tr>
      <tr>
        <td>2</td>
        <td>6.15e-7</td>
        <td>1.15e-6</td>
        <td>1.84e-6</td>
        <td>2.70e-6</td>
      </tr>
      <tr>
        <td>3</td>
        <td>1.66e-10</td>
        <td>4.65e-10</td>
        <td>9.96e-10</td>
        <td>1.83e-9</td>
      </tr>
      <tr>
        <td>4</td>
        <td>2.53e-14</td>
        <td>1.18-13</td>
        <td>3.53e-13</td>
        <td>8.33e-13</td>
      </tr>
      <tr>
        <td>5</td>
        <td>2.05e-18</td>
        <td>1.91e-17</td>
        <td>8.59e-17</td>
        <td>2.70e-16</td>
      </tr>
      <tr>
        <td>6</td>
        <td>6.91e-23</td>
        <td>1.94e-21</td>
        <td>1.45e-20</td>
        <td>6.38e-20</td>
      </tr>
      <tr>
        <td>7</td>
        <td>x</td>
        <td>1.12e-25</td>
        <td>1.68e-24</td>
        <td>1.11e-23</td>
      </tr>
      <tr>
        <td>8</td>
        <td>x</td>
        <td>2.84e-30</td>
        <td>1.28e-28</td>
        <td>1.40e-27</td>
      </tr>
      <tr>
        <td>9</td>
        <td>x</td>
        <td>x</td>
        <td>5.75e-33</td>
        <td>1.26e-31</td>
      </tr>
      <tr>
        <td>10</td>
        <td>x</td>
        <td>x</td>
        <td>1.16e-37</td>
        <td>7.68e-36</td>
      </tr>
      <tr>
        <td>11</td>
        <td>x</td>
        <td>x</td>
        <td>x</td>
        <td>2.83e-40</td>
      </tr>
      <tr>
        <td>12</td>
        <td>x</td>
        <td>x</td>
        <td>x</td>
        <td>4.78e-45</td>
      </tr>
</table> : "" }
{submitState == -4 ? <br/> : "" }
{submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>For comparison, the odds of winning the Mega Millions Jackpot are given at <a href="https://www.megamillions.com/How-to-Play.aspx" style={{ color: 'red' }}>1 in 302,575,350</a>, almost double the odds of guessing 3 out of 12 words correctly.<br/><br/>
		With those same odds, you could win the Mega Millions jackpot 5 times (1 ticket each time) and still be 82 times less likely to guess 12 out of 12 random words correctly in this game.</span> : "" }

      {submitState == -4 ? <h2>Donations</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>If you like what we do, feel free to donate to help cover our hosting and operational costs!</span> : "" }
      {submitState == -4 ? <br/> : "" }
      {submitState == -4 ? <input onChange={e => setDonationAmt(e.target.value)} value={donationAmt} placeholder="BNB Donation Amt" style = {{ fontSize: 20 }} disabled={donationDisabled}/> : "" }
      {submitState == -4 ? <br/> : "" }
      {submitState == -4 ? <button onClick={makeDonation} style = {{ fontSize: 20 }} disabled={donationDisabled}> Make a donation </button> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>{donationText}</span> : "" }
      {submitState == -4 ? <h2>I won! What now?</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>If you defied the odds and won, feel free to reach out to the contact at the bottom of the page to list your name and/or website beside your winning entry. This may help point others in your direction as a verified (or inexplicably lucky) clairvoyant.</span> : "" }
      {submitState == -4 ? <h2>I am having some issues or other problems</h2> : "" }
      {submitState == -4 ? <span style={{ textAlign: "left", width: "75%" }}>You may contact the following email for assistance:</span> : "" }
      {submitState == -4 ? <br/> : "" }
      {submitState == -4 ? <br/> : "" }
      {submitState == -4 ? <ReCAPTCHA sitekey="6LeGlnUpAAAAACjwEzpl6PWG3rwPSmEMThYYWW1w" onChange={e => setDisplayEmail(true)}/> : "" }
      {submitState == -4 ? <br/> : "" }
      {submitState == -4 && displayEmail == true ? "Email: OverplannedAdulting@gmail.com" : "" }
      {submitState == -4 ? <br/> : "" }
      {submitState == -4 ? <button onClick={() => {setSubmitState(null); setTopText("")}} style = {{ fontSize: 20 }}> Back to main page </button> : "" }



      {submitState == -5 ? <button onClick={() => {setStateDisplayText2("");setSubmitState(null);window.scrollTo(0, 0)}} style = {{ fontSize: 20 }}> Back to main page </button> : "" }
      {submitState == -5 ? <br/> : "" }
      {submitState === -5 && ("How many words would you like to guess?")}
      {submitState === -5 && (<select value={selectedItem} onChange={handleChange} style = {{ fontSize: 20 }} disabled={subDisabled}>
        <option value="6">6 Words</option>
        <option value="8">8 Words</option>
        <option value="10">10 Words</option>
        <option value="12">12 Words</option>
      </select>)}
      {submitState === -5 && ("Your guesses - abdomen, camel, dizzy, etc:")}
      {submitState === -5 && (<input onChange={e => setWord1(e.target.value)} value={word1} placeholder="1st word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -5 && (<input onChange={e => setWord2(e.target.value)} value={word2} placeholder="2nd word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -5 && (<input onChange={e => setWord3(e.target.value)} value={word3} placeholder="3rd word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -5 && (<input onChange={e => setWord4(e.target.value)} value={word4} placeholder="4th word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -5 && (<input onChange={e => setWord5(e.target.value)} value={word5} placeholder="5th word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -5 && (<input onChange={e => setWord6(e.target.value)} value={word6} placeholder="6th word" style = {{ fontSize: 20 }} disabled={subDisabled} />)}
      {submitState === -5 && selectedItem > 6 ?  <input onChange={e =>  setWord7(e.target.value)}  value={word7}  placeholder="7th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -5 && selectedItem > 6 ?  <input onChange={e =>  setWord8(e.target.value)}  value={word8}  placeholder="8th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -5 && selectedItem > 8 ?  <input onChange={e =>  setWord9(e.target.value)}  value={word9}  placeholder="9th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -5 && selectedItem > 8 ?  <input onChange={e => setWord10(e.target.value)} value={word10} placeholder="10th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -5 && selectedItem > 10 ? <input onChange={e => setWord11(e.target.value)} value={word11} placeholder="11th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState === -5 && selectedItem > 10 ? <input onChange={e => setWord12(e.target.value)} value={word12} placeholder="12th word" style = {{ fontSize: 20 }} disabled={subDisabled} /> : ""}
      {submitState == -5 ? <br/> : "" }
      {submitState === -5 && (<button onClick={makeSubmission2} style = {{ fontSize: 20 }} disabled={subDisabled}>Make Submission</button>)}
      {submitState == -5 ? stateDisplayText : "" }
      {submitState === -6 || submitState === -7 ? <table>
    <tr>
      <th>Guess</th>
      <th>Answer</th>
      <th>Result</th>
    </tr>
    {stateGuessesAndAnswers2.map(d => (
      <tr>
        <td style={d.color}>{d.guess}</td>
        <td style={d.color}>{d.answer}</td>
        <td style={d.color}>{d.result}</td>
      </tr>
      ))}
  </table> : "" }
      {submitState == -7 ? <br/> : "" }
      {submitState == -7 ? <span style={{ textAlign: "left", width: "75%" }}>You passed the screening test! Click <a href={"mailto:OverplannedAdulting@gmail.com?subject=AgeOfAscention Screening Test Passed!&body="+bodyTextFormatted} style={{ color: 'red' }}>here</a> and populate with the required information.</span> : "" }
      {stateDisplayText2}
      {submitState === -6 || submitState === -7 ? <br/> : "" }
      {submitState === -6 || submitState === -7 ? <button onClick={() => {setStateDisplayText2("");setSubmitState(-5);window.scrollTo(0, 0)}} style = {{ fontSize: 20 }}> Back to guess page </button> : "" }
      {submitState == -5 ? <br/> : "" }
      {submitState == -5 ? <button onClick={() => {setStateDisplayText2("");setSubmitState(null);window.scrollTo(0, 0)}} style = {{ fontSize: 20 }}> Back to main page </button> : "" }



      <br/><br/>
      </header>
    </div>
  );
}
export default App;
